<template>
  <div id="app">
      <el-container style="display:block">
        <el-header class="head" v-if="show">
          <div class="logo">
            <el-image
            style="width: 60px; height: 60px"
            :src="logo"
            :fit="'contain'"
          ></el-image>
            <h4>科房超级管理系统</h4>
          </div>
          <div class="usercenter">
            <el-avatar v-if="icon != null" :src="icon"></el-avatar>
            <el-avatar v-else >{{userinfo.name}}</el-avatar>
            <el-dropdown class="selection" @command="handleCommand">
              <span  class="el-dropdown-link">
                {{userinfo.name}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="b">个人中心</el-dropdown-item>
                <el-dropdown-item command="a">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-container>
          <div v-if="show" :class="['hamer',move]" @click="tocollapse" >
              <i  class="el-icon-s-fold"></i>
          </div>
          <el-aside v-if="show" width="200px" class="left-menu" >
              <el-menu default-active="/"  class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" router>
                  <el-menu-item index="/">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">首页</span>
                  </el-menu-item>

                  <!-- 数字技术部门 -->
                  <el-submenu index="0"> 
                    <template slot="title">
                      <i class="el-icon-s-platform"></i>
                      <span slot="title">数字技术部</span>
                    </template>
                        <el-menu-item index="/sqlsearch">
                            sql查询
                        </el-menu-item>
                  </el-submenu>

                  <!-- 艺术书店 -->
                  <el-submenu index="1"> 
                    <template slot="title">
                      <i class="el-icon-s-shop"></i>
                      <span slot="title">生活+ 小程序订单</span>
                    </template>
                    <el-menu-item index="/shopbill">
                      订单查询
                    </el-menu-item>
                    <el-menu-item index="/reserve">
                      预约数据
                    </el-menu-item>
                  </el-submenu>

                  <!-- 智慧湾 -->
                  <el-submenu index="2"> 
                    <template slot="title">
                      <i class="el-icon-s-shop"></i>
                      <span slot="title">智慧湾数据管理</span>
                    </template>
                    <el-menu-item index="/meter">
                      水电费表格
                    </el-menu-item>
                  </el-submenu>


                  
                  <el-menu-item v-if="userinfo.bid==12" index="/gl">
                    <i class="el-icon-setting"></i>
                    <span slot="title">管理功能</span>
                  </el-menu-item>
              </el-menu> 
          </el-aside>
          <el-main :id="content" :class="[hide,move]"><router-view></router-view></el-main>
        </el-container>     
      </el-container>
      
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      hide: "hide",
      logo: this.$logo,
      wendang: "active",
      exam: "",
      userinfo: {},
      icon: "",
      move: "",
      isCollapse: false,
      content: "",
    };
  },
  methods: {
    // 自动登陆
    autologin() {
      if (localStorage.getItem("token")) {
        //  this.logintype = true
        this.$router.replace({ path: "/" });
      } else {
        // this.logintype = false
        this.$router.replace({ path: "/login" });
      }
    },
    // 切换标签栏
    changetab(index) {
      if (index == 1) {
        this.exam = "";
        this.wendang = "active";
      } else {
        this.wendang = "";
        this.exam = "active";
      }
    },
    //获取账号信息
    getuserinfo() {
      if (localStorage.getItem("token")) {
        axios({
          method: "post",
          url: this.$host + this.$api.userinfo,
          data: {
            token: localStorage.getItem("token"),
          },
        }).then((res) => {
          var info = JSON.stringify(res.data.user);
          localStorage.setItem("userinfo", info);
          this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
          this.icon = this.userinfo.icon;
        });
      }
    },
    // 退出登录
    quitlogin() {
      this.$confirm(
        "此操作会让你退出登陆,你目前尚未保存的内容也会消失,是否继续",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$message({
          type: "success",
          message: "登出成功",
        });
        localStorage.removeItem("token");
        localStorage.removeItem("userinfo");
        this.$router.replace({ path: "/login" });
      });
    },
    // 操作下拉菜单的点击事件
    handleCommand(command) {
      if (command == "a") {
        this.quitlogin();
      } else {
        this.$router.push({ path: "/usercenter" });
      }
    },
    // 侧边栏
    // 侧边栏缩进展开
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    tocollapse() {
      if (this.move == "") {
        this.move = "xs";
        this.isCollapse = true;
        console.log(this.isCollapse);
      } else {
        this.move = "";
        this.isCollapse = false;
        console.log(this.isCollapse);
      }
    },
  },
  mounted() {
    this.autologin();
  },
  watch: {
    $route() {
      console.log(this.$route.name);
      this.show = this.$route.name !== "login";
      if (this.show == false) {
        this.hide = "hide";
        this.content = "";
      } else {
        this.hide = "";
        this.content = "content";
        this.getuserinfo();
      }
    },
  },
  create() {
    this.show = this.$route.name !== "login";
    if (this.show == true) {
      this.content = "content";
      this.hide = "";
    } else {
      this.content = "";
      this.hide = "hide";
    }
  },
};
</script>
<style>
.usercenter {
  margin-left: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usercenter .selection {
  color: #fff;
  margin-left: 20px;
}
.hide {
  padding: 0 !important;
  margin: 0 !important;
}
.logo {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.head {
  width: 100%;
  height: 60px !important;
  position: fixed;
  border-bottom: 1px solid #ccc;
  line-height: 30px;
  background: #001938;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}
.piece {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-left: 53%;
}
.piece-tab {
  height: 80px;
}
.piece a {
  margin-left: 40px;
  display: block;
  padding: 22px 0;
  text-align: center;
  color: #fff;
}
.piece a:hover {
  color: #ebfff0;
}
.piece .active {
  color: #ebfff0;
  text-shadow: 1px 1px 1px #4fc08d;
  font-weight: 700;
}
.piece .active::after {
  content: "";
  display: inline-block;
  position: relative;
  bottom: 2px;
  left: 0px;
  width: 45px;
  height: 4px;
  background: #4fc08d;
}
/* 侧边栏 */
.el-submenu__title:hover {
  color: #4fc08d;
  background: #ebfff0 !important;
  border-radius: 999px;
}
.el-submenu__title {
  border-radius: 999px;
}
.el-menu--collapse {
  height: 100%;
  box-shadow: 0 8px 12px #ebedf0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 100%;
  box-shadow: 0 8px 12px #ebedf0;
}
.el-menu-item {
  border-radius: 999px !important;
}
.el-menu-item.is-active {
  background-color: #ebfff0 !important;
  border-radius: 999px;
  font-weight: 600;
  color: #4fc08d !important;
  font-size: 13px;
}
.el-menu-item:hover {
  background: #ebfff0 !important;
  border-radius: 999px !important;
  color: #4fc08d !important ;
}
.hamer {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 64px;
  left: 210px;
  font-size: 25px;
  rotate: 0deg;
  transition: all 0.5s ease;
}
.hamer.xs {
  width: 30px;
  height: 30px;
  position: fixed;
  left: 60px;
  font-size: 25px;
  rotate: 180deg;
  z-index: 1001;
  transition: all 0.5s ease;
}
.left-menu {
  width: 20%;
  height: 100vh;
  top: 60px;
  position: fixed;
  z-index: 1000;
  overflow: -moz-scrollbars-none;
}
.left-menu::-webkit-scrollbar {
  display: none;
}

#content {
  position: relative;
  top: 70px;
  width: 100%;
  margin-left: 250px;
  margin-right: 20px;
  /* padding: 10px 0; */
  transition: all 0.3s ease;
  overflow: hidden;
}
#content.xs {
  margin-left: 134px;
}
</style>
