// const API_BASE_URL = 'http://localhost:80/tool/web/';
// const API_TOOl_URL = 'http://localhost:80/tool/';
// 生产地址
const API_BASE_URL = 'https://wiki.kefan.com/tool/web/';
const API_TOOl_URL = 'https://wiki.kefan.com/tool/';
const API_TOOl_IMG = 'https://wiki.kefan.com/tool/img/';


const API = {
    user:API_BASE_URL + 'user.php',//用户接口
    list:API_BASE_URL+'list.php',//列表接口
    uploadexcel:API_BASE_URL+'uploadexcel.php',//上传excel接口
    excel:API_BASE_URL+"excel.php"//excel操作接口
}


const config = {
    API,
    API_BASE_URL,
    API_TOOl_URL,
    API_TOOl_IMG
}

export default config