import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../config'
import axios from 'axios'
Vue.use(VueRouter)
let API = config.API

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    component: () => import('../views/usercenter.vue')
  },
  {
    path: '/anlimore',
    name: 'anlimore',
    component: () => import('../views/anlimore.vue')
  },
  //案例详情
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/article.vue')
  },
  // 管理
  {
    path: '/gl',
    name: 'gl',
    component: () => import('../views/gl.vue')
  },
  // 行政
  {
    path: '/fp',
    name: 'fp',
    component: () => import('../views/xingzheng/fp.vue')
  },
  {
    path: '/jb',
    name: 'jb',
    component: () => import('../views/xingzheng/jb.vue')
  },
  // 招商
  {
    path: '/khgl',
    name: 'khgl',
    component: () => import('../views/zhaoshang/khgl.vue')
  },
  {
    path: '/kjgl',
    name: 'kjgl',
    component: () => import('../views/zhaoshang/kjgl.vue')
  },
  {
    path: '/htgl',
    name: 'htgl',
    component: () => import('../views/zhaoshang/htgl.vue')
  },
  {
    path: '/zdqr',
    name: 'zdqr',
    component: () => import('../views/zhaoshang/zdqr.vue')
  },
  // 物业
  {
    path: '/bxgl',
    name: 'bxgl',
    component: () => import('../views/wuye/bxgl.vue')
  },
  {
    path: '/gygl',
    name: 'gygl',
    component: () => import('../views/wuye/gygl.vue')
  },
  {
    path: '/sdfgl',
    name: 'sdfgl',
    component: () => import('../views/wuye/sdfgl.vue')
  },
  {
    path: '/wbgl',
    name: 'wbgl',
    component: () => import('../views/wuye/wbgl.vue')
  },
  //财务
  {
    path: '/cwhtgl',
    name: 'cwhtgl',
    component: () => import('../views/caiwu/cwhtgl.vue')
  },
  {
    path: '/cwzdgl',
    name: 'cwzdgl',
    component: () => import('../views/caiwu/cwzdgl.vue')
  },
  {
    path: '/cwzdbj',
    name: 'cwzdbj',
    component: () => import('../views/caiwu/cwzdbj.vue')
  },
  // 数字技术部
  {
    path: '/sqlsearch',
    name: 'sqlsearch',
    component: () => import('../views/szjsb/sqlsearch.vue'),
    meta: { requiresAuth: true }
    
  },
  //生活+
  {
    path: '/kcsearch',
    name: 'kcsearch',
    component: () => import('../views/yssd/kcsearch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shopbill',
    name: 'shopbill',
    component: () => import('../views/yssd/shopbill.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('../views/yssd/reserve.vue'),
    meta: { requiresAuth: true }
  },
  //智慧湾
  {
    path: '/meter',
    name: 'meter',
    component: () => import('../views/zhw/meter.vue'),
    meta: { requiresAuth: true }
  },

]



//解决router版本升级导致的跳转报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return routerPush.call(this,location).catch(err=>err)
}
const router = new VueRouter({
  mode:'history',
  routes
})

const vue = new Vue();

router.beforeEach((to,from,next) => {
  // 判断是否是需要进行权限验证的路由
  if (to.meta.requiresAuth) {
    // 在这里进行权限验证
    axios.post(API.user, { do:"authenticated",token: localStorage.getItem('token'), router: to.name })
      .then(response => {
        if (response.data.data.allowed) {
          // 如果权限验证通过，则继续导航到目标路由
          next();
        } else {
          // 如果权限验证不通过，则重定向到其他页面
          if(!response.data.data.login){
            next('/login');
          }else{
            next(false);
            vue.$message({
              message: '您没有权限访问该页面',
              type: 'error'
            });
          }
        }
      })
      .catch(error => {
        console.error('Error during authentication check:', error);
        next(false); // 中断导航
      });
  } else {
    next();
  }
});


export default router
